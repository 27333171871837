/* eslint-disable */
import { base, baseUpload, baseXlsx } from '@/utils/api'

const ENDPOINT = '/simpros'

export default {
    show(clinicId, id) {
        const config = {
            params: { clinicId }
        };
        return base.get(`${ENDPOINT}/${id}`, config);
    },
    getItems(page, clinicId, tableId, query, types, status) {
        const config = {
            params: { page, clinicId, query, types, status }
        };
        return base.get(`${ENDPOINT}/${tableId}/table`, config);
    },
    getIds(clinicId, tableId) {
        const config = {
            params: { clinicId, tableId }
        };
        return base.get(`${ENDPOINT}/batch/ids`, config);
    },
    create(props) {
        return base.post(`${ENDPOINT}`, props);
    },
    update(id, props) {
        return base.put(`${ENDPOINT}/${id}`, props);
    },
    delete(id) {
        return base.delete(`${ENDPOINT}/${id}`);
    },
    deleteBatch(clinicId, ids) {
        return base.put(`${ENDPOINT}/batch/delete`, { clinicId, ids });
    },
}