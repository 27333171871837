export const TYPE_PROCEDURE = 'PROCEDURE';
export const TYPE_OTHER_EXPENSES = 'OTHER_EXPENSES';
export const TYPE_MAT_MED_SIMPRO = 'MAT_MED_SIMPRO';
export const TYPE_MAT_MED_BRASINDICE = 'MAT_MED_BRASINDICE';
export const TYPE_MATERIAL = 'MATERIAL';
export const TYPE_MEDICINE = 'MEDICINE';
export const TYPE_PRODUCT = 'PRODUCT';

export const TYPES = [
    TYPE_PROCEDURE,
    TYPE_OTHER_EXPENSES,
    TYPE_MAT_MED_SIMPRO,
    TYPE_MAT_MED_BRASINDICE,
];

export const hiddenTypes = [
    TYPE_MAT_MED_SIMPRO,
    TYPE_MAT_MED_BRASINDICE,
]

export const TYPE_OPTIONS = [
    { label: 'Procedimentos', value: TYPE_PROCEDURE },
    { label: 'Outras despesas', value: TYPE_OTHER_EXPENSES },
    { label: 'Simpro', value: TYPE_MAT_MED_SIMPRO },
    { label: 'Brasindice', value: TYPE_MAT_MED_BRASINDICE },
];

export const parseTypeToLabel = (type) => {
    const types = {
        [TYPE_PROCEDURE]: 'Procedimento',
        [TYPE_OTHER_EXPENSES]: 'Outras despesas',
        [TYPE_MAT_MED_SIMPRO]: 'Simpro',
        [TYPE_MAT_MED_BRASINDICE]: 'Brasindice',
        [TYPE_MATERIAL]: 'Materiais',
        [TYPE_MEDICINE]: 'Medicamentos',
    };
    return types[type] ?? type;
}

export const parseTypeToLabelGrouped = (type) => {
    const types = {
        [TYPE_PROCEDURE]: 'Procedimento',
        [TYPE_OTHER_EXPENSES]: 'Outras despesas',
        [TYPE_MAT_MED_SIMPRO]: 'Mat/Med',
        [TYPE_MAT_MED_BRASINDICE]: 'Mat/Med',
        [TYPE_MATERIAL]: 'Materiais',
        [TYPE_MEDICINE]: 'Medicamentos',
    };
    return types[type] ?? type;
}

export const TABLE_PROCEDURE_TYPE_PRIVATE = 'particular'
export const TABLE_PROCEDURE_TYPE_HEALTH_PLAN = 'health_plan'