<template>
    <main class="matmed-details-container">
        <div class="breadcrumb-wrapper">
            <div class="breadcrumb-routers">
                <a href="/configuracoes-da-clinica/tabelas">Tabelas</a>
                <ChevronRight class="crevron-right" />
                <a :href="`/tables/d/${tableId}`">Tabela {{ table?.name }}</a>
                <ChevronRight class="crevron-right" />
                <span>Atualizações</span>
            </div>
            <div class="actions">
                <div class="toggle-select-wrapper">
                    <toggle-button
                        :width="35"
                        v-model="multiPages"
                        :disabled="loading"
                        :sync="true"
                        :height="22"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    />
                    <span>Selecionar de todas as páginas</span>
                </div>
            </div>
        </div>
        <div>
            <LayoutBrasindiceUpdates 
                v-if="table && table.type === CONSTANTS.TYPE_MAT_MED_BRASINDICE"
                :multiPages="multiPages"
                :table="table"
                :brasindiceProp="brasindiceProp"
                :brasindicePropId="$route.params.id"
            />
            <LayoutSimproUpdates 
                v-if="table && table.type === CONSTANTS.TYPE_MAT_MED_SIMPRO"
                :multiPages="multiPages"
                :table="table"
                :simproProp="simproProp"
                :simproPropId="$route.params.id"
            />
        </div>
    </main>
</template>
<script>
import tableApi from '@tables/api'
import brasindiceApi from '@matMed/brasindice.api'
import simproApi from '@matMed/simpro.api'
import * as CONSTANTS from '@tables/utils/constants';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        ChevronRight: () => import('@/assets/icons/chevron-right.svg'),  
        LayoutBrasindiceUpdates: () => import('@matMed/layouts/LayoutBrasindiceUpdates'), 
        LayoutSimproUpdates: () => import('@matMed/layouts/LayoutSimproUpdates'),
    },
    data() {
        return {
            CONSTANTS,
            loading: false,
            clinic: getCurrentClinic(),
            multiPages: false,
            tableId: this.$route.params.tableId,
            type: this.$route.params.type,
            table: null,
            brasindiceProp: null,
            simproProp: null,
        }
    },
    async created() {
        await this.getTable()
        switch (this.table.type) {
            case CONSTANTS.TYPE_MAT_MED_BRASINDICE:
                await this.getBrasindiceProp()
                break
            case CONSTANTS.TYPE_MAT_MED_SIMPRO:
                await this.getSimproProp()
                break
        }
    },
    methods: {
        async getTable() {
            this.loading = true
            try {
                const { data } = await tableApi.getTable(this.tableId)
                this.table = data
            } catch(error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
        async getBrasindiceProp() {
            this.loading = true
            try {
                const { data } = await brasindiceApi.show(this.clinic.id, this.$route.params.id)
                this.brasindiceProp = data
            } catch(error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
        async getSimproProp() {
            this.loading = true
            try {
                const { data } = await simproApi.show(this.clinic.id, this.$route.params.id)
                this.simproProp = data
            } catch(error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.matmed-details-container {
    padding: 1rem;
}
.breadcrumb-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 1rem 0;

    .crevron-right {
        width: 18px;
        stroke: var(--neutral-600);
    }
}
.breadcrumb-routers {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: var(--blue-500);
    font-weight: 600;
    span {
        color: var(--type-placeholder);
    }
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .toggle-select-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
}
</style>